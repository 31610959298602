@import '_styles/variables';
@import '_styles/mixins';

.HomeRoute {

  &__tilesPageSection {
    margin-top: 0;
  }

  &__tiles {
    position: relative;
    padding-bottom: 0.1px;

    @include respondAbove(s) {
      background: rgba(255,255,255, 0.06);
    }
  }

  &__tilesBar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    background: var(--fh-colorBackground);

    @include respondAbove(s) {
      height: calc((min(1560px, 100vw) - (var(--fh-spacerBlock) * 3)) / 6);
    }

    @include respondAbove(m) {
      height: calc((min(1560px, 100vw) - (var(--fh-spacerBlock) * 4)) / 8.5);
    }
  }
}


